import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer class="w-full pt-16 bg-black">

        <div class="container px-5 py-4 mx-auto">
          <p class="text-xl text-white capitalize text-center">© 2024 All rights reserved
            SpicyHarissa Studio </p>

        </div>
      </footer>
    </div>
  )
}

export default Footer