import React from 'react'
import Seamless from './../../Images/Seamless.png'
import Immersive from './../../Images/immersive.png'
import Innovative from './../../Images/Innovative.png'
import Multiplayer from './../../Images/multiplayer.png'
const Services = () => {
    return (
      <div name="services" className="pt-24 w-full min-h-screen h-ful bg-black">
      <section >
      <div className="container mx-auto text-center">
      <h2 class="w-full mb-24 text-5xl font-bold leading-tight text-center underline decoration-red-500 underline-offset-8 text-white ">
        What We Offer
      </h2>
        <div className="  justify-center px-4 md:px-6 gap-8">
          <div className="bg-black  rounded-lg flex flex-wrap sm:flex-nowrap shadow-lg p-8  sm:gap-44  transform hover:scale-105 transition duration-300">
          <div className="content-center order-2 sm:order-1">
            <h3 className="sm:text-4xl text-2xl font-semibold text-red-500 mb-4">
              Multiplayer Games
            </h3>
            <p className="text-gray-300 sm:text-2xl text-xl text-start ">
              We specialize in crafting dynamic and engaging multiplayer games
              that bring people together through cooperative and competitive
              play.
            </p>
            </div>
            <img
              src={Multiplayer} 
              alt="Multiplayer Games"
              className="sm:w-2/5  object-cover rounded-md mb-4 order-1 sm:order-2"
              loading="lazy"
            />
            
          </div>
          <div className="bg-black  rounded-lg  shadow-lg p-8  flex flex-wrap sm:flex-nowrap sm:gap-44  transform hover:scale-105 transition duration-300">
            <img
              src={Innovative}
              alt="Innovative Gameplay"
              className="sm:w-2/5  object-cover rounded-md mb-4"
              loading="lazy"
            />
            <div className="content-center">
            <h3 className="sm:text-4xl text-2xl font-semibold text-red-500 mb-4">
              Innovative Gameplay
            </h3>
            <p className="text-gray-300 sm:text-2xl text-xl text-start">
              Our games feature innovative gameplay mechanics designed to keep
              players on their toes and provide fresh, memorable experiences.
            </p>
            </div>
          </div>
          <div className="bg-black  rounded-lg  flex flex-wrap sm:flex-nowrap shadow-lg p-8 sm:gap-44  transform hover:scale-105 transition duration-300">
          <div className="content-center order-2 sm:order-1">
            <h3 className="sm:text-4xl text-2xl font-semibold text-red-500 mb-4">
            UI/UX Design & Immersive Experiences
            </h3>
            <p className="text-gray-300 sm:text-2xl text-xl text-start ">
            We craft visually stunning and intuitive interfaces that draw players into immersive worlds. 
            Our seamless design enhances gameplay, making each interaction both engaging and memorable.
            </p>
            </div>
            <img
              src={Immersive} 
              alt="UI/UX Design & Immersive Experiences"
              className="sm:w-2/5  object-cover rounded-md mb-4 order-1 sm:order-2"
              loading="lazy"
            />
            
          </div>
          <div className="bg-black  rounded-lg  shadow-lg p-8  flex flex-wrap sm:flex-nowrap sm:gap-44 transform hover:scale-105 transition duration-300">
            <img
              src={Seamless} 
              alt="Seamless Playability"
              className="sm:w-2/5  object-cover rounded-md mb-4"
              loading="lazy"
            />
            <div className="content-center">
            <h3 className="sm:text-4xl text-2xl font-semibold text-red-500 mb-4">
            Seamless Playability
            </h3>
            <p className="text-gray-300 sm:text-2xl text-xl text-start">
            We prioritize seamless gameplay, ensuring our multiplayer games
              run smoothly and keep players engaged, whether they're teaming up
              or competing.
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    )
}

export default Services
