import React from 'react'
import { Link } from 'react-scroll'
import { FaLinkedinIn, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
const ContactUs = () => {
  return (

    <div name="contactus" className="pt-24 w-full  h-full bg-black">
      <h2 class="mb-24 text-5xl font-bold leading-tight text-center underline decoration-red-500 underline-offset-8 text-white ">
        Get in Touch
      </h2>

      <div className=" items-center justify-center gap-4 px-4 md:px-6 text-white">
        <div className="space-y-3 text-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="border-2 rounded-lg shadow-lg p-6 hover:scale-105 hover:border-red-500 transition-transform duration-300">
              <h3 className="text-4xl font-bold mb-4">Contact Us</h3>
              <div className="space-y-8  text-xl md:text-3xl">
                <div className="flex items-center gap-2 ">
                  <MapPinIcon className="h-10 w-10 " />
                  <p>Tunisia, Sousse</p>
                </div>
                <div className="flex items-center gap-2">
                  <PhoneIcon className="h-10 w-10 " />
                  <a href="tel:+21652993317" className="hover:underline" prefetch={false} > +216 52 993 317</a>
                </div>
                <div className="flex items-center gap-2">
                  <MailIcon className="h-10 w-10 " />
                  <a href="mailto:contact@SpicyHarissa.com" target="_blank" className="hover:underline" prefetch={false}>contact@SpicyHarissa.com</a>
                </div>
                <div className="flex items-start gap-4">
                  <div className="flex gap-4">
                    Social Medias :
                    <a href="#" className="" prefetch={false}>
                      <TwitterIcon className="h-10 w-10" />
                      <span className="sr-only">Twitter</span>
                    </a>
                    <a href="#" className="" prefetch={false}>
                      <FacebookIcon className="h-10 w-10" />
                      <span className="sr-only">Facebook</span>
                    </a>
                    <a href="https://www.linkedin.com/company/spicyharissa/about/" target='_blank'  className="" prefetch={false}>
                      <LinkedinIcon className="h-10 w-10" />
                      <span className="sr-only">LinkedIn</span>
                    </a>
                    <a href="#" className="" prefetch={false}>
                      <InstagramIcon className="h-10 w-10" />
                      <span className="sr-only">Instagram</span>
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div className="border-2 rounded-lg shadow-lg p-6 hover:scale-105 hover:border-red-500 transition-transform duration-300">

              <form action="https://formspree.io/f/xnnarbqg" method="POST" className="grid gap-6">
                <div className="grid gap-2">
                  <label htmlFor="name" className="text-white text-2xl font-semibold">Name</label>
                  <input
                    id="name"
                    type="text" 
                    name="name"
                    placeholder="Enter your name"
                    className="p-2 rounded-md bg-gray-900 text-white focus:outline-none focus:ring-2 focus:ring-red-400 transition-colors duration-200"
                  />
                </div>
                <div className="grid gap-2">
                  <label htmlFor="email" className="text-white text-2xl font-semibold">Email</label>
                  <input
                    id="email"
                    type="email" 
                    name="email"
                    placeholder="Enter your email"
                    className="p-2 rounded-md bg-gray-900 text-white focus:outline-none focus:ring-2 focus:ring-red-400 transition-colors duration-200"
                  />
                </div>
                <div className="grid gap-2">
                  <label htmlFor="message" className="text-white text-2xl font-semibold">Message</label>
                  <textarea
                    id="message"
                    type="text" 
                    name="message"
                    placeholder="Enter your message"
                    className="p-2 rounded-md bg-gray-900 text-white min-h-[120px] focus:outline-none focus:ring-2 focus:ring-red-400 transition-colors duration-200"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 rounded-md bg-red-600 text-white font-bold tracking-wide hover:bg-red-700 transition-colors duration-200"
                >
                  Submit
                </button>
              </form>



            </div>
          </div>

        </div>

      </div>
    </div>

  )
}
function FacebookIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
    </svg>
  )
}


function InstagramIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
    </svg>
  )
}


function LinkedinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
      <rect width="4" height="12" x="2" y="9" />
      <circle cx="4" cy="4" r="2" />
    </svg>
  )
}


function MailIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  )
}


function MapPinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  )
}


function PhoneIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  )
}


function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  )
}

export default ContactUs