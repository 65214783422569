import React from 'react'
import img from './../../Images/DOG Emote Confused.png'
const AboutUs = () => {
  return (
    <div name="aboutus" className="pt-24 text-white w-full min-h-screen h-full bg-black">
      <h2 class="w-full mb-24 text-5xl font-bold leading-tight text-center underline decoration-red-500 underline-offset-8 text-white ">
        About Us
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 md:p-8 items-center justify-center text-white">
        <div className="col-span-1  rounded-lg  shadow-lg p-4 md:p-8   hover:border-red-500 transform hover:scale-105 transition duration-300">
          <h5
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className='text-white font-bold text-3xl sm:text-5xl border-s-2 border-red-400'
          >
            Who Are We ?
          </h5>
          <br></br>
          <br></br>
          <h1
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className='text-xl sm:text-2xl text-white'
          >
            Spicy Harissa was founded by a group of passionate friends with a shared love for video games, we embarked on our journey by independently creating games and leveraging our collective expertise in game development.


          </h1>
          <br></br>
          <h1
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className='text-xl sm:text-2xl text-white'
          >
            With a commitment to crafting immersive and engaging experiences, our studio is driven by a unified vision to deliver quality games that resonate with players worldwide.
          </h1>

        </div>

        <div className=' rounded-lg justify-center flex shadow-lg p-2 max-w-fit hover:border-red-500 transform hover:scale-105 transition duration-300'>
          <img className=' rounded-lg  shadow-lg transform transition duration-300 w-2/3' width={450} src={img}></img>
        </div>

      </div>

    </div>
  )
}

export default AboutUs