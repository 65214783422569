import React from 'react';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-black">
      <div className='max-w-[1000px] mx-4 sm:mx-auto flex flex-col justify-center h-full'>
        <motion.h5
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className='text-white font-bold text-5xl sm:text-8xl border-s-2 border-red-400'
        >
          Spicy Harissa Studio
        </motion.h5>
        <br></br>
        <br></br>
        <motion.h1
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className='text-3xl sm:text-4xl text-white'
        >
          Crafting Multiplayer Games With Passion
        </motion.h1>
      </div>
    </div>
  );
}

export default Home;
