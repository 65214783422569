import React from 'react'
import { useState } from 'react'
import './Project.css';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Doubtit from "./../../Images/doubtit.png";
import Soon from "./../../Images/soon.png"
import ScreenShot from "./../../Images/Select_Cards.png";
import AndroidImg from "./../../Images/androind.png"
function Projects() {
    const [showDescription1, setShowDescription1] = useState(false)
    const [showDescription2, setShowDescription2] = useState(false)
    const [showDescription3, setShowDescription3] = useState(false)
    return (
        <div name="projects" className="pt-24 w-full min-h-screen h-full bg-black">
            <h2 class="w-full mb-24 text-5xl font-bold leading-tight text-center text-white underline decoration-red-500 underline-offset-8">
                Projects
            </h2>
            <div>
            <div name="project1">
                <div className="imgmid bg-gradient-to-l from-black h-45 to-orange-950 items-center place-content-end align-middle mt-8 cursor-pointer"
                    onClick={() => setShowDescription1(!showDescription1)}
                >
                    <img className=" w-64 md:w-auto  hover:scale-105 transition duration-300" src={Doubtit}></img>
                    <div className="flex justify-end">
                        {!showDescription1 ? <FaPlus color='rgb(209 213 219)' size={30} /> : <FaMinus color='rgb(209 213 219)' size={30} />}

                    </div>
                </div>
                {showDescription1 && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 p-8 text-white">
                        <div className="col-span-2">
                            <h2 className="text-4xl font-bold ">
                                Doubt It- <span className="text-red-500">Card Game</span>
                            </h2>
                            <p className="mt-4 sm:text-2xl text-xl">
                            Get ready for hours of fun with Doubt-It. Perfect for 2 to 8 players, Doubt-It is easy to learn but offers endless strategic possibilities, making it a must-have for game nights, family gatherings, and casual play with friends.
                            </p>
                            <p className="mt-4 sm:text-2xl text-xl">

                            So gather your friends and family for epic battles of wit, or play online against strangers. </p>
                            <div className="mt-8 flex justify-center">
                                <img className='rounded-lg shadow-xl' width={1000} src={ScreenShot}></img>
                            </div>
                        </div>
                        <div className="space-y-8 justify-center flex flex-col items-center">
                            <div className="text-center">
                                <p className="sm:text-4xl text-2xl font-bold">Year:</p>
                                <p className="text-xl sm:text-2xl">May 2024</p>
                            </div>
                            <div className="text-center">
                                <p className="sm:text-4xl text-2xl font-bold">Developer:</p>
                                <p className="text-xl sm:text-2xl">SpicyHarissa Games</p>
                            </div>
                            <p className="sm:text-4xl text-2xl font-bold">Available on :</p>
                            <div className=" justify-center">
                                <a href='https://play.google.com/store/apps/details?id=com.SpicyHarissa.Doubt_It' target="_blank"><img  width={80} src={AndroidImg}></img></a>
                            </div>
                        </div>
                    </div>
                )}
             </div>
             <div name="project1">
                <div className="imgmid bg-gradient-to-l  h-45  hover:scale-105 transition duration-300 items-center place-content-end align-middle mt-8 "
                   
                >
                    <img className=' w-2/3 md:w-1/3 ' src={Soon}></img>
                
                </div>
                </div>
            </div>

        </div>

    )
}

export default Projects